.container {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.page__text-container {
  width: 100%;
  padding: 80px 0;
  max-width: 1600px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.page__title {
  font-size: 48px;
  line-height: 1.3;
  margin-bottom: 16px;
}

.page__counter {
  font-size: 32px;
  line-height: 1.3;
  margin-bottom: 16px;
  color: #798897;
}

.page__tree-head {
  width: 100%;
  height: 100%;
  transform: rotate(180deg);
  margin: 0;
}

.page__logo {
  max-width: 200px;
  height: auto;
  margin: 0 auto;
}

.page__tree-main-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

.page__tree-main-img {
  width: 100%;
  height: auto;
}

.page__toys-container {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 200px);
  padding: 20px 100px 20px 100px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 100%;
}

.page__toys-container:last-of-type {
  padding-bottom: 0;
  padding-top: 0;
}

.page__toys-wrap-first {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.page__toys-wrap-second {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  overflow: hidden;
}

.toy__wrap {
  width: auto;
  height: 70%;
  position: relative;
  box-shadow: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toy__wrap:hover {
  transform: scale(1.05);
  transition: transform .15s ease-in-out .15s;
}

.toy__wrap:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
}

.toy__container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.toy__transform-1 {
  transform: translate(-5%, 10%) rotate(3deg);
}

.toy__transform-2 {
  transform: translate(-5%, -10%);
}

/*.toy__transform-3 {*/
/*  transform: translate();*/
/*}*/

.toy__transform-4 {
  transform: translate(0, 20%);
}

.toy__transform-5 {
  transform: translate(10%, 5%) rotate(-3deg);

}

.toy__transform-6 {
  transform: translate(10%, 20%);
}

/*.toy__transform-7 {*/
/*  transform: translate();*/
/*}*/

.toy__transform-8 {
  transform: translate(-10%, 20%) rotate(3deg);
}

.toy__transform-9 {
  transform: translate(-20%, 20%);
}

.toy__img {
  width: auto;
  height: 100%;
  max-width: 160px;
  max-height: 190px;
}

.create__toy-img {
  width: auto;
  height: 100%;
  max-width: 200px;
  max-height: min(200px, 70%);
}

.create__toy-img:hover {
  transform: scale(1.05);
  transition: transform .15s ease-in-out .15s;
}

.toy__name {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 60%;
  height: 100%;
  text-wrap: wrap;
  padding: 20px 10px 0 10px;
  font-size: 16px;
}

.modal__overlay {
  position: fixed;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modal__wrap {
  position: absolute;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e4e7ea;
  width: max-content;
  padding: 20px 40px 40px 40px;
  display: flex;
  flex-direction: column;
  max-width: min(1000px, 80%);
  max-height: 500px;
  overflow: auto;
}

.modal__close-btn {
  width: max-content;
  height: 100%;
  background-color: transparent;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.modal__close-icon {
  width: 100%;
  height: 100%;
  max-height: 24px;
  max-width: 24px;
}

.modal__btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.modal__info {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  gap: 20px;
  width: max-content;
}

.modal__info-to {
  font-size: 36px;
}

.modal__info-wish {
  font-size: 32px;
}

.modal__info-wish-wrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modal__info-wish-title {
  font-size: 24px;
  color: #798897;
}

.create__link {
  width: 100%;
  max-width: 160px;
  height: 100%;
  cursor: pointer;
}

@media (max-width: 800px) {
  .page__toys-container {
    padding: 20px;
    max-width: 80%;
    width: 80%;
    margin: 0 auto;
    right: 0;
  }

  .page__tree-main-container {
    max-width: 100%;
  }

  .page__toys-wrap-first {
    max-width: 100%;
    width: 100%;
  }

  .toy__container {
    width: 100%;
    max-width: 100%;
  }

  .toy__wrap {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }

  .toy__img {
    width: 100%;
    height: auto;
  }

  .page__toys-wrap-second {
    width: 75%;
    height: 80%;
    margin: 0 auto;
    overflow: initial;
  }

  .toy__name {
    padding-top: 10px;
    font-size: 12px;
  }

  .modal__wrap {
    padding: 10px 20px 20px 20px;
  }

  .modal__close-icon {
    max-height: 12px;
    max-width: 12px;
  }

  .modal__info-wish-title {
    font-size: 12px;
  }

  .modal__info-to {
    font-size: 14px;
  }

  .modal__info-wish {
    font-size: 14px;
  }

  .page__title {
    font-size: 32px;
    line-height: 1.3;
    margin-bottom: 16px;
  }

  .page__counter {
    font-size: 24px;
    line-height: 1.3;
    margin-bottom: 16px;
  }
}

@media (max-width: 600px) {
  .page__toys-wrap-second {
    width: 80%;
  }

  .toy__name {
    font-size: 8px;
  }

}
