@font-face {
  font-family: 'Tondo Corp';
  src: url(./fonts/TondoCorp-Bold.woff) format('woff'),
       url(./fonts/TondoCorp-Light.woff) format('woff'),
       url(./fonts/TondoCorp-Regular.woff) format('woff'),
       url(./fonts/TondoCorp-Bold.ttf) format('truetype'),
       url(./fonts/TondoCorp-Light.ttf) format('truetype'),
       url(./fonts/TondoCorp-Regular.ttf) format('truetype')
;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Tondo Corp, "Tondo Corp";
  font-weight: normal;
}

code {
  font-family: Tondo Corp, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
